import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { CoreServicesBrowserService } from './core/services/browser/core-services-browser.service';
import { ViewsHomeComponent } from './views/home/views-home.component';
import { HttpClientModule } from '@angular/common/http';
import { HammerModule } from '@angular/platform-browser';
import { ViewsModule } from './views/views.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {BrowserViewPort} from 'gl-w-browser-frontend';
import { InViewportModule } from 'ng-in-viewport';
import { provideLottieOptions } from 'ngx-lottie';

const routes: Routes = [
  {path: '', component: ViewsHomeComponent},
  {path: '**', component: ViewsHomeComponent}
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(
      routes,
      {
    onSameUrlNavigation: 'reload',
    enableTracing: false,
    useHash: false,
    // Go direct to the content
    // anchorScrolling: 'enabled'
}
    ),

    HammerModule,

    CoreModule,
    SharedModule,
    ViewsModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    InViewportModule
  ],
  providers: [
    provideLottieOptions({
      player: () => import('lottie-web'),
    })
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {
  constructor(private _browser: CoreServicesBrowserService) {
    BrowserViewPort.setFullHeightListener();
    BrowserViewPort.setFullWidthListener();
  }
}
