import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TProjectCategory } from '../../../../core/models/project.model';

@Injectable({
  providedIn: 'root'
})
export class ViewsHomeContentProjectsService {
  category: TProjectCategory = '';

  isVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  unsubscribe$: Subject<any> = new Subject<any>();
  dataUnsubscribe$: Subject<any> = new Subject<any>();

  constructor() { }

  unsubscribeVisible(): void {
    // @ts-ignore //Todo check error
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  unsubscribeData(): void {
    // @ts-ignore //Todo check error
    this.dataUnsubscribe$.next();
    this.dataUnsubscribe$.complete();
  }
}
