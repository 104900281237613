<div class="stackoverflow-main-content-container" *ngIf="profile != null">
  <div class="profile-title-container">
    <a rel="noreferrer" class="link-container" href="https://stackoverflow.com/users/4305317/gláucio-leonardo-santana?tab=profile" target="_blank">
      <img
        class="icon"
        alt="Stackoverflow icon"
        src="assets/img/logo/stackoverflow.svg">
      <h2 class="title">Stackoverflow</h2>
      <img
        class="icon external-link"
        alt="External link icon"
        src="assets/img/icon/links/external-link-dark-gray.svg">
    </a>
  </div>

  <p class="description">
    Take a look at my stackoverflow profile (<a rel="noreferrer" href="https://stackoverflow.com/users/4305317/gláucio-leonardo-santana?tab=profile" class="link">click here</a>).
  </p>

  <a rel="noreferrer" class="avatar-container" [href]="profile.url" target="_blank">
    <img
      class="profile-picture"
      alt="profile for Gl&#225;ucio Leonardo Sant&#39;ana at Stack Overflow, Q&amp;A for professional and enthusiast programmers"
      title="profile for Gl&#225;ucio Leonardo Sant&#39;ana at Stack Overflow, Q&amp;A for professional and enthusiast programmers"
      [src]="profile.picture">

    <div class="reputation-container reputation">
      <span class="reputation-label">Reputation</span>
      <span class="reputation-quantity">{{ profile.reputation }}</span>
    </div>
<!--    <div class="reputation-container reached">-->
<!--      <span class="reputation-label">Reached</span>-->
<!--      <span class="reputation-quantity">{{ profile.reached }}</span>-->
<!--    </div>-->
    <div class="reputation-container answers">
      <span class="reputation-label">Answers</span>
      <span class="reputation-quantity">{{ profile.answers }}</span>
    </div>
    <div class="reputation-container questions">
      <span class="reputation-label">Questions</span>
      <span class="reputation-quantity">{{ profile.questions }}</span>
    </div>

    <div class="badges-wrapper">
      <div class="badges-container">
        <div class="badge-item-container {{ item.color }}" *ngFor="let item of profile.badges">
          <div class="icon-container" [id]="'badge-' + item.id"></div>
          <span class="quantity">{{ item.quantity }}</span>
        </div>
      </div>
    </div>
  </a>
</div>
