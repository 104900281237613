import { Component } from '@angular/core';
import Decimal from 'decimal.js';
import { takeUntil } from 'rxjs/operators';
import { ViewsHomeContentProfilesService } from '../views-home-content-profiles.service';
import { INpmPackageData } from './views-home-content-profiles-npm.interface';
import { ViewsHomeContentProfilesNpmService } from './views-home-content-profiles-npm.service';

@Component({
  selector: 'app-views-home-content-profiles-npm',
  templateUrl: './views-home-content-profiles-npm.component.html',
  styleUrls: ['./views-home-content-profiles-npm.component.scss']
})
export class ViewsHomeContentProfilesNpmComponent {
  packages: INpmPackageData[] = [];
  totalPackages: number = null;
  initialized = false;

  constructor(private _service: ViewsHomeContentProfilesNpmService, private _profileService: ViewsHomeContentProfilesService) {
    _profileService.isVisible$.pipe(takeUntil(_profileService.npmUnsubscribe$)).subscribe(async visible => {
      if (visible && !this.initialized) {
        this.initialized = true;
        await this.getPackages();
        _profileService.unsubscribeProfile(_profileService.npmUnsubscribe$);
      }
    });
  }

  async getPackages() {
    const data: INpmPackageData[] = await this._service.getData();

    this.packages = [...data];
    this.totalPackages = new Decimal(data[0].packages).toNumber();
  }
}
