<div class="profiles-main-content-container" id="profiles">
  <div class="profiles-header">
    <ng-lottie
      width="140vw"
      height="auto"
      containerClass="profiles-top-leaves-animation"
      [options]="bottomLeavesOptions"
      (animationCreated)="onAnimationCreated($event, 1)"
    ></ng-lottie>

    <h1 class="profiles-page-title">My profiles</h1>

    <p class="profiles-detail">
      <strong class="its-time">...iiiiiiiiiit's timeeeee!</strong><strong *ngIf="visible" id="man">\o/</strong><br>
      The current section contains just an overview on my profiles.
      Please, don't miss the chance to visit them and see the full details...
    </p>

    <app-views-home-content-profiles-github></app-views-home-content-profiles-github>
    <app-views-home-content-profiles-npm></app-views-home-content-profiles-npm>
    <app-views-home-content-profiles-stackoverflow></app-views-home-content-profiles-stackoverflow>
    <app-views-home-content-profiles-linkedin></app-views-home-content-profiles-linkedin>
  </div>
</div>
