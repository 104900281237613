import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IProjectServer, TManagePortfolioAction, TProjectCategory } from '../../../models/project.model';

@Injectable({
  providedIn: 'root'
})
export class CoreServicesDataProjectsService {

  constructor(
    private _http: HttpClient
  ) { }

  //region Read
  async getAll(type: TManagePortfolioAction, category: TProjectCategory = ''): Promise<IProjectServer[]> {
    return await this.getItem(type, category, null) as IProjectServer[];
  }
  async getById(id: number, type: TManagePortfolioAction, category: TProjectCategory): Promise<IProjectServer> {
    return await this.getItem(type, category, id) as IProjectServer;
  }
  private getItem(type: TManagePortfolioAction, category: TProjectCategory, id: number = null): Promise<IProjectServer[] | IProjectServer> {
    const idUrl = id === null ? '' : `/${id}`;
    const parsedCategory: string = category == null || category === '' ? '' : `&category=${category}`;
    const mainProject = category == null || category === '' ? '&isMainProject=1' : '';
    const url = `${this.urlType}${idUrl}?action=${type}${parsedCategory}${mainProject}`;

    return new Promise( (resolve, reject) => {
      this._http.get<IProjectServer | IProjectServer[]>(url, { reportProgress: true, observe: 'events', responseType: 'json' })
        .subscribe(async event => {
            if (event.type === HttpEventType.Response) {
              const data: IProjectServer | IProjectServer[] = event.body;
              resolve(data);
            }
          },
          error => {
            let errorMessage;

            if (error.includes('Http failure response')) {
              errorMessage = 'Unable to establish connection with server!';
            } else {
              errorMessage = error;
            }

            reject(errorMessage);
          });
    });
  }
  //endregion

  //region Process Url
  get urlType(): string {
    return 'https://admin.glaucioleonardo.com.br/api/projects';
  }
  //endregion
}
