import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TLogoBackground, TLogoBackgroundFormat } from './shared-components-logos-glaucio.interface';

@Component({
  selector: 'app-shared-components-logos-glaucio',
  templateUrl: './shared-components-logos-glaucio.component.html',
  styleUrls: [ './shared-components-logos-glaucio.component.scss' ]
})
export class SharedComponentsLogosGlaucioComponent {
  @Output() click$: EventEmitter<void> = new EventEmitter();

  @Input() clickable = false;
  @Input() backgroundType: TLogoBackground = 'default';
  @Input() backgroundFormat: TLogoBackgroundFormat = 'default';

  constructor() {}

  onClick() {
    if (this.clickable) {
      this.click$.emit();
    }
  }
}
