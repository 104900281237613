import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewsHomeContentProfilesService {
  isVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  unsubscribe$: Subject<any> = new Subject<any>();
  gitUnsubscribe$: Subject<any> = new Subject<any>();
  npmUnsubscribe$: Subject<any> = new Subject<any>();
  stackUnsubscribe$: Subject<any> = new Subject<any>();
  linkedinUnsubscribe$: Subject<any> = new Subject<any>();

  constructor() { }

  unsubscribeVisible(): void {
    // @ts-ignore //Todo check error
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  unsubscribeProfile(unsubscribe$: Subject<any>): void {
    // @ts-ignore //Todo check error
    unsubscribe$.next();
    unsubscribe$.complete();
  }
}
