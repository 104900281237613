import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IProjectItemGallery } from 'src/app/core/models/project.model';
import {ElementDragging} from 'gl-w-elements-frontend';
import { IPlayerData } from 'gl-ng-outputs-frontend';

@Component({
  selector: 'app-views-home-content-projects-project-detail-gallery',
  templateUrl: './views-home-content-projects-list-gallery.component.html',
  styleUrls: ['./views-home-content-projects-list-gallery.component.scss']
})
export class ViewsHomeContentProjectsListGalleryComponent implements OnInit {
  @Input() content: IProjectItemGallery[] = [];
  @Output() $onClick: EventEmitter<IProjectItemGallery> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    const slider: HTMLDivElement = document.querySelector('.projects-detail-gallery-wrapper');
    ElementDragging.horizontal(slider);
  }

  onPlayerReady(result: IPlayerData) {
    result.player.pause();
  }
}
