import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentsLogosGlaucioComponent } from './components/logos/glaucio/shared-components-logos-glaucio.component';
import { SharedComponentsAnimationsTypeCursorComponent } from './components/animations/type-cursor/shared-components-animations-type-cursor.component';

@NgModule({
  declarations: [
    SharedComponentsLogosGlaucioComponent,
    SharedComponentsAnimationsTypeCursorComponent,
  ],
  exports: [
    SharedComponentsLogosGlaucioComponent,
    SharedComponentsAnimationsTypeCursorComponent,
  ],
  imports: [
    CommonModule,
  ]
})
export class SharedModule { }
