import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { takeUntil } from 'rxjs/operators';
import { CoreServicesAnimationService } from '../../../../core/services/animation/core-services-animation.service';
import { ViewsHomeContentProfilesService } from './views-home-content-profiles.service';
import { Location } from '@angular/common';
import { CoreServicesBrowserService } from '../../../../core/services/browser/core-services-browser.service';

@Component({
  selector: 'app-views-home-content-main-skills',
  templateUrl: './views-home-content-profiles.component.html',
  styleUrls: ['./views-home-content-profiles.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out',
              style({ height: 300, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 300, opacity: 1 }),
            animate('1s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class ViewsHomeContentProfilesComponent implements AfterViewInit {
  visible = false;
  loopVisibility = false;

  topLeavesAnimation: AnimationItem;
  topLeavesElement: HTMLElement;

  constructor(
    private _service: ViewsHomeContentProfilesService,
    private _animationService: CoreServicesAnimationService,
    private _location: Location,
    private _browser: CoreServicesBrowserService
  ) {
    _service.isVisible$.pipe(takeUntil(this._service.unsubscribe$)).subscribe(visible => {
      this.loopVisibility = visible;

      if (visible) {
        this.visible = visible;
      }

      this._animationService.processLoopAnimationVisibility(this.topLeavesElement, this.topLeavesAnimation, visible);
    });
  }

  bottomLeavesOptions: AnimationOptions = {
    path: '../assets/anims/our-team/bottom-leaves/data.json',
    loop: true,
    autoplay: true,
    renderer: 'svg',
    name: 'personThree'
  };

  onManLoad(element: HTMLElement) {
    if (element != null) {
      const positions = [
        '|o|', '\\o/',
        '|o|', '/o/',
        '|o|', '\\o/',
        '|o|', '\\o\\',
        '|o|',
      ];
      let id = 0;

      setInterval(() => {
        if (this.loopVisibility) {
          if (id >= positions.length - 1) {
            id = 0;
          }
          element.textContent = positions[id];
          id++;
        }
      }, 10000);
    }
  }

  ngAfterViewInit(): void {
    const man = document.querySelector('#profiles #man') as HTMLElement;
    this.onManLoad(man);

    const container = document.querySelector('#profiles.profiles-main-content-container');
    this.topLeavesElement = container.querySelector('.profiles-top-leaves-animation');
  }

  onAnimationCreated(animation: AnimationItem, index: number) {
    switch (index) {
      case 1: this.topLeavesAnimation = animation; break;
    }
  }
}
