<gl-component-modal-loading [defaultMessage]="loadingMessage">
  <gl-component-logo-text-only
    slot="user-content"
    title="glaucio"
    subtitle="sant'ana"
  ></gl-component-logo-text-only>
</gl-component-modal-loading>

<gl-component-menu
  [title]="environment.sideMenuData.title"
  [subtitle]="environment.sideMenuData.subtitle"
  [className]="environment.sideMenuData.className"
  [menuItems]="menuItems"
  [showSettings]="environment.sideMenuData.showSettings"
  [systemVersion]="environment.sideMenuData.systemVersion"
  [menuBottomTitle]="environment.sideMenuData.menuBottomTitle"
  [menuBottomVersion]="environment.sideMenuData.menuBottomVersion"
  [menuPosition]="environment.sideMenuData.menuPosition"
  [menuLogo]="environment.sideMenuData.menuLogo"
  [settingsTarget]="environment.sideMenuData.settingsTarget"
  [settingsUrl]="environment.sideMenuData.settingsUrl"
></gl-component-menu>

<div class="main-container" #mainContainer>
  <gl-component-header-default
    theme="dark-semi-opaque"
    [systemLogo]="true"
    [hideOnScrollDown]="true"
    [menuButtons]="header.menuButtons"
    (keyDown$)="header.onShortcut($event)">
    <app-shared-components-logos-glaucio
      slot="system-logo"
      backgroundType="transparent"
    ></app-shared-components-logos-glaucio>
  </gl-component-header-default>

  <app-app-views-home-content></app-app-views-home-content>
  <section class="footer-container">
    <gl-component-footer-simple
      description="Gláucio Sant'ana"
    ></gl-component-footer-simple>
  </section>
</div>
