import { Injectable } from '@angular/core';
import { ISideMenuData } from './core-services-environment.interface';

@Injectable({
  providedIn: 'root'
})
export class CoreServicesEnvironmentService {
  readonly glaucioLogoColorful: string = '../assets/img/logo/glaucio-logo-colorful.svg';

  sideMenuData: ISideMenuData = {
    title: 'Gláucio Sant\'ana',
    subtitle: '',
    footer: `Gláucio Sant\'ana`,
    className: '',
    showSettings: false,
    systemVersion: '',
    menuBottomTitle: 'Gláucio Sant\'ana',
    menuBottomVersion: '',
    menuPosition: 'right',
    menuLogo: `${this.glaucioLogoColorful}`,
    settingsTarget: '_blank',
    settingsUrl: null
  };

  constructor() {
    console.log(`------------------ Gláucio Sant\'ana ------------------`);
    console.log('Thanks for visiting ;^)');
    console.log(`-------------------------------------------------------`);
  }
}
