import { Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GlComponentMenuService } from 'gl-ng-menus-frontend';
import { IMenuItem } from 'gl-ng-menus-frontend/gl-ng-menus-frontend/src/lib/component/menu/item/gl-component-menu-item.interface';
import * as env from '../../../environments/environment';
import { CoreServicesEnvironmentService } from '../../core/services/environment/core-services-environment.service';
import { ViewsHomeHeaderService } from './header/views-home-header.service';
import { CoreServicesBrowserService } from '../../core/services/browser/core-services-browser.service';

@Component({
  selector: 'app-app-views-home',
  templateUrl: './views-home.component.html',
  styleUrls: [ './views-home.component.scss' ]
})
export class ViewsHomeComponent implements OnInit, AfterViewInit {
  @ViewChild('mainContainer') mainContainer: ElementRef<HTMLDivElement>;

  protected readonly onclick = onclick;
  protected readonly event = event;

  private readonly _menuIcon = '../assets/img/icon/side-menu/';
  menuItems: IMenuItem[] = [
    {
      content: 'Welcome',
      href: '',
      fragment: 'welcome',
      src: `${this._menuIcon}welcome.svg`,
      alt: 'Welcome Link',
      absolute: false
    },
    {
      content: 'Our team',
      href: '',
      fragment: 'our-team',
      src: `${this._menuIcon}team.svg`,
      alt: 'Our Tem',
      absolute: false
    },
    {
      content: 'Projects',
      href: '',
      fragment: 'projects',
      src: `${this._menuIcon}projects.svg`,
      alt: 'Projects',
      absolute: false
    },
    {
      content: 'Profiles',
      href: '',
      fragment: 'profiles',
      src: `${this._menuIcon}profiles.svg`,
      alt: 'Profiles',
      absolute: false
    },
  ];
  loadingMessage = env.environment.defaultLoadingMessage;

  constructor(
    public environment: CoreServicesEnvironmentService,
    public header: ViewsHomeHeaderService,
    public menu: GlComponentMenuService,
    private _location: Location,
    private _browser: CoreServicesBrowserService
  ) {}

  ngOnInit(): void {
    this.setLocation();
  }

  ngAfterViewInit(): void {
    window.addEventListener('scroll', () => {
      this.setLocation();
    }, {passive: true, capture: true, once: true });
  }

  private setLocation() {
    const welcome: HTMLElement = document.querySelector('#welcome');
    const team: HTMLElement = document.querySelector('#our-team');
    const projects: HTMLElement = document.querySelector('#projects');
    const profiles: HTMLElement = document.querySelector('#profiles');

    if (this._browser.isInViewport(welcome)) {
      this._location.go('/#welcome');
    } else if (this._browser.isInViewport(team)) {
      this._location.go('/#our-team');
    } else if (this._browser.isInViewport(projects)) {
      this._location.go('/#projects');
    } else if (this._browser.isInViewport(profiles)) {
      this._location.go('/#profiles');
    }

    // const id = 'profiles';
    // // console.log(new SmoothScroll(`#${this.fragment}`));
    // const scroll = new SmoothScroll(`#${id}`, {
    //   speed: 1000,
    //   speedAsDuration: true,
    //   easing: 'easeInOutQuad',
    //   updateURL: true,
    //   popstate: true
    // });
    // scroll.animateScroll(profiles);
  }
}
