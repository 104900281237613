<div class="project-item-container adding-row-animated">
  <div class="project-detail-content-container">
    <div
      class="project-detail-main-content-container"
      [class.portrait]="pictureOrientation === 'portrait'"
      (swipeleft)="onSwipeContentOverview($event, 'left')" (swiperight)="onSwipeContentOverview($event, 'right')">
      <img
        class="overview-content-cover"
        [src]="contentFitSrc"
        alt="Cover or Fit Content"
        (click)="setContentFit()">
      <img
        class="overview-content-description"
        [src]="descriptionVisibilitySrc"
        alt="Show or hide description"
        (click)="setDescriptionVisibility()">
      <img
        class="overview-content-swipe"
        src="assets/img/icon/project-detail/swipe.svg"
        alt="Swipe Gallery">
      <img
        #imagePreview
        *ngIf="contentOverviewData?.type != null && (contentOverviewData?.type == 'image' || isSafari)"

        draggable="false"
        class="overview-content-image"
        [class.portrait]="pictureOrientation === 'portrait'"
        [class.content-fit]="contentFit === 'crop'"
        [src]="contentOverviewData.src"
        [alt]="contentOverviewData.description"
        (load)="onImagePreviewLoad($event, imagePreview)">
      <gl-component-outputs-video-preview
        *ngIf="contentOverviewData?.type != null && contentOverviewData?.type == 'video' && !isSafari"
        draggable="false"
        class="overview-content-video"
        id="overview-content"
        backgroundSquare="assets/img/background/project-video-background-portrait.jpg"
        backgroundWidescreen="assets/img/background/project-video-background-portrait.jpg"
        [class.portrait]="pictureOrientation === 'portrait'"
        [sourceMp4]="contentOverviewData.src"

        [fitVideoToContainer]="contentFit === 'crop'"
        [showControls]="true"
        [squareActive]="true"
        [showBackground]="true"
        [showVideoSpeed]="true"
        [masterVideo]="true"
        [showVolume]="false"
        [loop]="true"
        [muted]="true"
        [autoplay]="false"
        videoPreload="metadata"
        (Playing$)="onPlaying($event)"
      ></gl-component-outputs-video-preview>
      <div
        [class.no-data]="contentOverviewData?.description?.length == null || contentOverviewData?.description?.length === 0"
        [class.video]="contentOverviewData?.type === 'video'"
        class="description-container"
        [class.invisible]="hideDescription">
        <span
          class="description"
        >{{ contentOverviewData.description }}</span>
      </div>
    </div>
    <app-views-home-content-projects-project-detail-gallery
      [content]="gallery"
      ($onClick)="onGalleryClick($event)"
    ></app-views-home-content-projects-project-detail-gallery>
  </div>


  <div class="details-content-container">
    <div class="details-header-container" [class.no-data]="!showTypes(category)">
      <div class="detail-header-wrapper">
        <h3 class="detail-header">{{ name }}</h3>
      </div>
      <div class="detail-platform-container">
        <div class="platform-type-container">
          <span class="platform-type">{{ category?.short_name }}</span>
        </div>
      </div>
    </div>

    <div class="detail-description-container" [class.no-data]="!showDescription()">
      <p class="detail-description"
         (swipeleft)="nextPreviousProject$.emit({ event: $event, type: 'left' })"
         (swiperight)="nextPreviousProject$.emit({ event: $event, type: 'right' })"
      >{{ description }}</p>
    </div>

    <div class="description-content-container">
      <div class="detail-summary">
        <div class="summary-container" [class.no-data]="!showTypes(platform)">
          <div class="summary-title-container">
            <img [src]="platform?.icon"
                 class="title-icon"
                 alt="Title icon">
            <span class="summary-title">Platform</span>
          </div>
          <span class="summary-content">{{ platform?.name }}</span>
        </div>

        <div class="summary-container" [class.no-data]="!showTypes(backend)">
          <div class="summary-title-container">
            <img [src]="backend?.icon"
                 class="title-icon"
                 alt="Title icon">
            <span class="summary-title">Back-End</span>
          </div>
          <span class="summary-content">{{ backend?.name }}</span>
        </div>

        <div class="summary-container" [class.no-data]="!showTypes(frontend)">
          <div class="summary-title-container">
            <img [src]="frontend?.icon"
                 class="title-icon"
                 alt="Title icon">
            <span class="summary-title">Front-End</span>
          </div>
          <span class="summary-content">{{ frontend?.name }}</span>
        </div>

        <div class="summary-container" [class.no-data]="!showTypes(database)">
          <div class="summary-title-container">
            <img [src]="database?.icon"
                 class="title-icon"
                 alt="Title icon">
            <span class="summary-title">Database</span>
          </div>
          <span class="summary-content">{{ database?.name }}</span>
        </div>

        <div class="summary-container" [class.no-data]="!showLanguages()">
          <div class="summary-title-container">
            <img [src]="languages == null ? '' : languages[randomLanguage]?.icon"
                 class="title-icon"
                 alt="Title icon">
            <span class="summary-title">Main languages</span>
          </div>
          <div class="summary-content-container main-languages">
            <img
              *ngFor="let item of languages; let i = index"
              [src]="item?.icon"
              [title]="item?.name"
              class="summary-icon"
              alt="Summary icon">
          </div>
        </div>
      </div>
    </div>

    <div class="detail-summary" [class.no-data]="!showOtherTechnologies()">
      <div class="summary-container">
        <div class="summary-title-container">
          <img
            [src]="icon"
            class="title-icon"
            alt="Title icon">
          <span class="summary-title">Other technologies</span>
        </div>
      </div>
      <div
        class="detail-description-container"
        (swipeleft)="nextPreviousProject$.emit({ event: $event, type: 'left' })"
        (swiperight)="nextPreviousProject$.emit({ event: $event, type: 'right' })">
        <p class="detail-description">{{ otherTechnologies }}</p>
      </div>
    </div>
  </div>
</div>
