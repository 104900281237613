import { Injectable } from '@angular/core';
import {BrowserFullScreen, IFullScreenAlt, IFullScreenSrc} from 'gl-w-browser-frontend';
import {IMenuButton} from 'gl-ng-buttons-frontend';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CoreServicesBrowserService {
  private readonly menuIcon: string = '../assets/img/icon/menu/';
  private readonly fullscreenIcon = `${this.menuIcon}enter-fullscreen.svg`;
  readonly fullScreenAlt: IFullScreenAlt = {
    enter: 'Enter fullscreen (Ctrl + Shift + F)',
    exit: 'Exit fullscreen (Ctrl + Shift + F)'
  };
  readonly fullScreenSrc: IFullScreenSrc = {
    enter: `${this.menuIcon}enter-fullscreen.svg`,
    exit: `${this.menuIcon}exit-fullscreen.svg`
  };

  fullscreen: IMenuButton = {
    src: this.fullscreenIcon,
    id: 'enter-exit-full-screen-button',
    alt: 'Enter fullscreen (Ctrl + Shift + F)',
    canReadonly: true,
    callback: () => this.toggleFullScreen()
  };

  constructor(private _location: Location) { }

  fullscreenListener() {
    BrowserFullScreen.listener(this.fullscreen, this.fullScreenAlt, this.fullScreenSrc);
  }

  toggleFullScreen() {
    return BrowserFullScreen.toggle(this.fullscreen, this.fullScreenAlt, this.fullScreenSrc);
  }

  isInViewport(el: HTMLElement): boolean {
    let top = el.offsetTop;
    let left = el.offsetLeft;
    const width = el.offsetWidth;
    const height = el.offsetHeight;

    // while (el.offsetParent) {
    el = (el as any).offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
    // }

    return (
      top < (window.pageYOffset + window.innerHeight) &&
      left < (window.pageXOffset + window.innerWidth) &&
      (top + height) > window.pageYOffset &&
      (left + width) > window.pageXOffset
    );
  }

  isExactInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();

    const bottom = rect.bottom;
    return (
      bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  changeUrlToSection(section: string) {
    const element: HTMLElement = document.getElementById(section);
    if (this.isInViewport(element)) {
      this._location.go(`#${section}`);
    }
  }
}
