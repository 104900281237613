<div class="projects-main-content-container" id="projects" aria-live="assertive">
  <div class="projects-header">
    <ng-lottie
      width="150vw"
      height="auto"
      containerClass="projects-top-leaves-animation"
      [options]="bottomLeavesOptions"
      (animationCreated)="onAnimationCreated($event, 'Bottom Leave')"
    ></ng-lottie>

    <h1 class="projects-page-title">Projects</h1>

    <p class="projects-detail">
      The following projects are segmented by Web (cross), Mobile and Desktop platforms.
      Click on the desired segment to list the respective projects or check out the main ones.
    </p>
  </div>

  <div class="project-platform-container">
    <ng-lottie
      *ngFor="let item of animations"
       width="30vw"
       height="30vw"
       containerClass="project-animation-{{ item.type.toLowerCase() }}"
       [options]="item.options"
       (animationCreated)="onAnimationCreated($event, item.type)"
       (click)="animate(item.type)"
       (dataReady)="onAnimationDataReady()"
    ></ng-lottie>
  </div>

  <div class="project-list-container">
    <div class="projects-page-subtitle-wrapper">
      <h2 class="projects-page-subtitle">{{  selectedPlatform }}</h2>
    </div>

    <app-views-home-content-projects-project-list
      [category]="activeCategory"
    ></app-views-home-content-projects-project-list>
  </div>
</div>
