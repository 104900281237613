<div class="our-team-main-content-container" id="our-team" aria-live="assertive">
  <section class="our-team-main-content" aria-live="assertive">
    <h1 role="heading" aria-level="1" class="our-team-page-title">Our team</h1>

    <div class="main-content-container">
      <div class="detail-container">
        <p class="our-team-detail">
          The following ones are our amazing multifunctional team such as the Designer, the Back-end & Front-end developer, the Program Manager,
          the Architect and our dear <b class="strikethrough">handsome</b> and amazing boss. <b class="strikethrough">(he’s asked to include it)</b>
        </p>

        <div class="our-team-people-container">
          <div class="our-team-person-container" *ngFor="let person of ourTeam">
            <ng-lottie
              width="70vw"
              height="70vw"
              [containerClass]="'team-animation-person ' + 'team-animation-person-' + person.index"
              [options]="person.animation"
              (animationCreated)="onTeamPersonOnAnimation($event, person.index)"
            ></ng-lottie>
            <div class="our-team-person-title-container {{ 'index-title-' + person.index }}">
              <span class="our-team-person-name">{{ person.name }}</span>
              <span class="our-team-person-position">{{ person.position }}</span>
            </div>
          </div>
        </div>

        <p class="our-team-detail next-section">
          This homogeneous team (LOL) is responsible for understanding, designing and developing simple and great solutions using
          different technologies presented in the next section…
        </p>

      </div>

      <ng-lottie
        [@inOutAnimation]
        width="100vw"
        height="auto"
        containerClass="team-animation"
        [options]="teamAnimationOptions"
        (animationCreated)="onTeamPersonOnAnimation($event, 4)"
      ></ng-lottie>
    </div>

    <ng-lottie
      [@inOutAnimation]
      width="65vw"
      height="65vw"
      containerClass="top-leaves-animation"
      [options]="topLeavesOptions"
      (animationCreated)="onTeamPersonOnAnimation($event, 5)"
    ></ng-lottie>

    <ng-lottie
      [@inOutAnimation]
      width="100vw"
      height="auto"
      containerClass="bottom-leaves-animation"
      [options]="bottomLeavesOptions"
      (animationCreated)="onTeamPersonOnAnimation($event, 6)"
    ></ng-lottie>
  </section>
</div>
