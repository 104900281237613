import { Injectable } from '@angular/core';
import { GlComponentMenuService } from 'gl-ng-menus-frontend';
import { CoreServicesBrowserService } from '../../../core/services/browser/core-services-browser.service';
import { IMenuButton } from 'gl-ng-buttons-frontend';
import { BrowserFullScreen } from 'gl-w-browser-frontend';

@Injectable({
  providedIn: 'root'
})
export class ViewsHomeHeaderService {
  menuButtons: IMenuButton[] = [];

  private readonly menuIcon: string = 'assets/img/icon/menu/';
  private readonly menuButtonIcon = `${this.menuIcon}menu-icon.svg`;

  constructor(
    private _browser: CoreServicesBrowserService,
    private _menu: GlComponentMenuService
  ) {
    const menu = {
      src: this.menuButtonIcon,
      id: 'open-menu-button',
      alt: 'Menu (Ctrl + M)',
      canReadonly: true,
      callback: (event) => { this.menu(event); }
    };

    if (BrowserFullScreen.available()) {
      this.menuButtons.push(this._browser.fullscreen);
    }

    this.menuButtons.push(menu);
  }

  initializeFullScreen() {
    this._browser.fullscreenListener();
  }
  enterFullScreen() {
    this._browser.toggleFullScreen();
  }
  menu(event: Event) {
    const action = this._menu.opened ? 'close' : 'open';
    this._menu[action](event);
  }

  onShortcut(event: KeyboardEvent) {
    if ((event.ctrlKey && event.shiftKey && event.key === 'F') || event.key === 'F11') {
      event.preventDefault();
      this.enterFullScreen();
    }

    if (event.ctrlKey && event.key === 'm') {
      event.preventDefault();
      this.menu(event);
    }
  }
}
