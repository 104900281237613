import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-views-home-content-profiles-linkedin',
  templateUrl: './views-home-content-profiles-linkedin.component.html',
  styleUrls: [ './views-home-content-profiles-linkedin.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewsHomeContentProfilesLinkedinComponent implements AfterViewInit {

  constructor() { }

  ngAfterViewInit(): void {
    // const styleSheet = setInterval(() => {
    //   const sheetName = 'https://static-exp1.licdn.com';
    //   // const sheetName = 'li';
    //   const stylesheet = document.querySelector('.linkedin-main-content-container link') as HTMLLinkElement;
    //   // const stylesheet = document.querySelector(`link[href*="${sheetName}"]`) as HTMLLinkElement;
    //   // const stylesheets: HTMLLinkElement[] = document.querySelectorAll(`link`) as any;
    //   // const stylesheet = stylesheets.filter(x => x.href.contains(sheetName))[0];
    //
    //   if (stylesheet != null) {
    //     clearInterval(styleSheet);
    //     stylesheet.disabled = true;
    //     stylesheet.parentNode.removeChild(stylesheet);
    //     this.ready = true;
    //   }
    // }, 1);
  }
}
