<div class="main-content-container" aria-live="assertive">
  <section class="header-container"></section>
  <section class="content-container">
    <app-views-home-content-introduction
      inViewport
      (inViewportCustomCheck)="testar($event)"
      [inViewportOptions]="{
        threshold: [0, 0.9]
      }"
      (inViewportAction)="browser.changeUrlToSection('welcome')"
    ></app-views-home-content-introduction>
    <app-views-home-content-our-team
      inViewport
      [inViewportOptions]="{
        threshold: [0.8, 0.9]
      }"
      (inViewportAction)="browser.changeUrlToSection('our-team')"
    ></app-views-home-content-our-team>
    <app-views-home-content-projects
      inViewport
      [inViewportOptions]="{
        threshold: [0.25, 0.7]
      }"
      (inViewportAction)="browser.changeUrlToSection('projects')"
    ></app-views-home-content-projects>
    <app-views-home-content-main-skills
      inViewport
      [inViewportOptions]="{
        threshold: [0.15, 0.9, 1],
      }"
      (inViewportAction)="browser.changeUrlToSection('profiles')"
    ></app-views-home-content-main-skills>
  </section>
</div>
