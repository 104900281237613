import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { AnimationDirection, AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { takeUntil } from 'rxjs/operators';
import { CoreServicesAnimationService } from '../../../../core/services/animation/core-services-animation.service';
import { ViewsHomeContentIntroductionService } from './views-home-content-introduction.service';
import { Location } from '@angular/common';
import { CoreServicesBrowserService } from '../../../../core/services/browser/core-services-browser.service';

@Component({
  selector: 'app-views-home-content-introduction',
  templateUrl: './views-home-content-introduction.component.html',
  styleUrls: ['./views-home-content-introduction.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out',
              style({ height: 300, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 300, opacity: 1 }),
            animate('1s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class ViewsHomeContentIntroductionComponent implements AfterViewInit {
  @ViewChild('introductionMainContainer') introductionMainContainer: ElementRef<HTMLDivElement>;

  visible = false;
  loopVisibility = false;

  backgroundOptions: AnimationOptions = {
    path: '../assets/anims/background/background-animation.json',
    loop: true,
    renderer: 'svg'
  };
  options: AnimationOptions = {
    path: '../assets/anims/glaucio-logo/glaucio-logo-animation.json',
    loop: false,
    rendererSettings: {  },
    renderer: 'svg'
  };

  logoDirection: AnimationDirection = 1;

  backgroundAnimation: AnimationItem;
  logoAnimation: AnimationItem;

  backgroundElement: HTMLElement;
  logoElement: HTMLElement;

  constructor(
    private _service: ViewsHomeContentIntroductionService,
    private _animationService: CoreServicesAnimationService,
    private _location: Location,
    private _browser: CoreServicesBrowserService
  ) {
    _service.isVisible$.pipe(takeUntil(this._service.unsubscribe$)).subscribe(visible => {
      this.loopVisibility = visible;

      if (visible) {
        this.visible = visible;
      }

      this._animationService.processLoopAnimationVisibility(this.backgroundElement, this.backgroundAnimation, visible);
      this._animationService.processLoopAnimationVisibility(this.logoElement, this.logoAnimation, visible);
    });
  }

  onAnimationCreated(animation: AnimationItem, index: number) {
    switch (index) {
      case 1: this.backgroundAnimation = animation; break;
      case 2: this.logoAnimation = animation; break;
    }
  }

  ngAfterViewInit(): void {
    const container = document.querySelector('app-views-home-content-introduction');

    this.backgroundElement = container.querySelector('.background-animation');
    this.logoElement = container.querySelector('.glaucio-logo');
  }

  onLogoComplete() {
    this.logoDirection = this.logoDirection === 1 ? -1 : 1;

    if (this.logoAnimation != null) {
      this.logoAnimation.setDirection(this.logoDirection);
      this.logoAnimation.play();
    }
  }
}
