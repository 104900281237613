<ng-lottie
  [@inOutAnimation]
  width="height(150)"
  height="auto"
  containerClass="introduction background-animation"
  [options]="backgroundOptions"
  (animationCreated)="onAnimationCreated($event, 1)"
></ng-lottie>

<div class="introduction-main-container" #introductionMainContainer id="welcome" aria-live="assertive">

  <div class="main-content-container">
    <ng-lottie
      width="90vw"
      height="auto"
      containerClass="glaucio-logo"
      [options]="options"
      role="img"
      aria-live="polite"
      (complete)="onLogoComplete()"
      (animationCreated)="onAnimationCreated($event, 2)"
    ></ng-lottie>

    <div class="content">
      <div class="greetings-container">
        <h1 class="greetings greet-1">Hello World!</h1>
        <h1 class="greetings greet-2">y there!</h1>
        <h1 class="greetings greet-3">ow r u doing?</h1>
        <h1 class="greetings greet-4">¡Hola! ¿Qué tal?</h1>
        <h1 class="greetings greet-5">Welcome n' enjoy! ;^)</h1>
        <app-shared-components-animations-type-cursor *ngIf="loopVisibility"></app-shared-components-animations-type-cursor>
      </div>

      <p class="description welcome adding-row-animated">
        Bem-vindo | Bienvenido | Benvenuti
      </p>

      <p class="description about-me part-1 adding-row-animated">
        Well, my name’s Gláucio Sant’ana. I’m a computer science and graphic design lover who is always studying,
        applying, falling, rising and applying these areas developing solutions with rich
        <b class="strong">user experience (UX)</b> and an elegant <b class="strong">user interface (UI)</b>.
      </p>

      <p class="description about-me part-2 adding-row-animated">
        To be more precise, I relate my professional experience to the development of business solutions with a robust
        background in system development (mostly as a full-stack), process enhancement, project management, continuous
        improvement and the specialization in design to boost my users and customers experience.
      </p>

      <p class="description about-me part-3 adding-row-animated">
        H’m! I think that’s good enough…
      </p>

      <p class="description about-me part-4 adding-row-animated">
        <b class="strong">Let’s meet our team.</b>
      </p>
    </div>
  </div>
</div>
