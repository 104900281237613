import { Injectable } from '@angular/core';
import { AnimationItem } from 'lottie-web';

@Injectable({
  providedIn: 'root'
})
export class CoreServicesAnimationService {

  constructor() { }

  processLoopAnimationVisibility(element: HTMLElement, animation: AnimationItem, visible: boolean): void {
    if (element != null) {
      visible ? animation?.play() : animation?.stop();
    }
  }
}
