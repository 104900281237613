import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { GlComponentModalLoadingService } from 'gl-ng-modals-frontend';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { takeUntil } from 'rxjs/operators';

import { TProjectCategory } from '../../../../core/models/project.model';
import { CoreServicesAnimationService } from '../../../../core/services/animation/core-services-animation.service';
import { ViewsHomeContentProjectsListService } from './list/views-home-content-projects-list.service';
import { IProjectAnimations, TProjectSelectedPlatform } from './views-home-content-projects.interface';
import { ViewsHomeContentProjectsService } from './views-home-content-projects.service';
import { environment } from '../../../../../environments/environment';
import 'howler';
import { Location } from '@angular/common';
import { CoreServicesBrowserService } from '../../../../core/services/browser/core-services-browser.service';


@Component({
  selector: 'app-views-home-content-projects',
  templateUrl: './views-home-content-projects.component.html',
  styleUrls: ['./views-home-content-projects.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out',
              style({ height: 300, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 300, opacity: 1 }),
            animate('1s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class ViewsHomeContentProjectsComponent implements OnInit, OnDestroy, AfterViewInit {
  visible = false;

  activeCategory: TProjectCategory = '';
  selectedPlatform: TProjectSelectedPlatform = 'Main Projects';

  bottomLeavesOptions: AnimationOptions = {
    path: '../assets/anims/our-team/bottom-leaves/data.json',
    loop: true,
    autoplay: true,
    renderer: 'svg',
    name: 'personThree'
  };

  bottomLeavesAnimation: AnimationItem;
  bottomLeavesElement: HTMLElement;

  private _platformElement: HTMLHeadElement;
  private _subtitle: HTMLHeadElement;
  private _webElement: HTMLHeadElement;
  private _mobileElement: HTMLHeadElement;
  private _desktopElement: HTMLHeadElement;
  private _swipeAnimation: HTMLImageElement;

  private _initialized = false;
  private _loadedItems = 0;
  private _swipeAnimated = false;

  webAnimationOptions: AnimationOptions;
  mobileAnimationOptions: AnimationOptions;
  desktopAnimationOptions: AnimationOptions;

  webAnimation: AnimationItem;
  mobileAnimation: AnimationItem;
  desktopAnimation: AnimationItem;

  animations: IProjectAnimations[] = [];

  constructor(
    public service: ViewsHomeContentProjectsService,
    private _dc: ChangeDetectorRef,
    private _loading: GlComponentModalLoadingService,
    private _location: Location,
    private _projectListService: ViewsHomeContentProjectsListService,
    private _animationService: CoreServicesAnimationService,
    private _browser: CoreServicesBrowserService
  ) {
    service.isVisible$.pipe(takeUntil(this.service.unsubscribe$)).subscribe(visible => {

      if (visible) {
        this.visible = visible;
      } else if (!this.visible){
        this._loading.hide();
      }

      this._animationService.processLoopAnimationVisibility(this.bottomLeavesElement, this.bottomLeavesAnimation, visible);
    });
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, false);
    // this.service.retrieveData(this.activePlatform);
  }
  ngAfterViewInit(): void {
    this._platformElement = document.querySelector('.projects-main-content-container .projects-page-title');
    this._initialized = true;

    const container = document.querySelector('#projects.projects-main-content-container');
    this.bottomLeavesElement = container.querySelector('.projects-top-leaves-animation');

  }
  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scroll, false); // third parameter
  }

  scroll = (): void => {
    this.processAnimationVisibility();

    if (!this._swipeAnimated) {
      this._swipeAnimation = document.querySelector('.overview-content-swipe');

      if (this._swipeAnimation != null && this.isExactInViewport(this._swipeAnimation)) {
        this._swipeAnimation.classList.add('animate');
        this._swipeAnimated = true;
      }
    }
  }

  async processAnimationVisibility(): Promise<void> {
    if (this._platformElement != null && this._initialized) {
      const personVisible = this.isInViewport(this._platformElement);

      if (personVisible) {
        this.webAnimationOptions = {
          path: '../assets/anims/projects/web/data.json',
          loop: false,
          autoplay: false,
          renderer: 'svg',
          name: 'web'
        };
        this.mobileAnimationOptions = {
          path: '../assets/anims/projects/mobile/data.json',
          loop: false,
          autoplay: false,
          renderer: 'svg',
          name: 'mobile'
        };
        this.desktopAnimationOptions = {
          path: '../assets/anims/projects/desktop/data.json',
          loop: false,
          autoplay: false,
          renderer: 'svg',
          name: 'desktop'
        };

        if (this.animations.length === 0) {
          this.animations.push({
            type: 'Web',
            options: this.webAnimationOptions
          });
          this.animations.push({
            type: 'Mobile',
            options: this.mobileAnimationOptions
          });
          this.animations.push({
            type: 'Desktop',
            options: this.desktopAnimationOptions
          });

          this.service.isVisible$.next(true);
          window.removeEventListener('scroll', this.scroll, false);
        }
      }
    }
  }
  isInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();

    const height = rect.height;
    const bottom = rect.bottom - (height * 2);
    return (
      bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }
  isExactInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();

    const bottom = rect.bottom;
    return (
      bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  onAnimationCreated(animation: AnimationItem, type: TProjectCategory | 'Bottom Leave'): void {
    switch (type) {
      case 'Web': this.webAnimation = animation; break;
      case 'Mobile': this.mobileAnimation = animation; break;
      case 'Desktop': this.desktopAnimation = animation; break;
      case 'Bottom Leave': this.bottomLeavesAnimation = animation; break;
    }
  }
  async onAnimationDataReady(): Promise<void> {
    this._loadedItems++;

    if (this._loadedItems === 3) {
      await this.setElements();

      this._webElement.classList.add('show');
      setTimeout(() => { this._mobileElement.classList.add('show'); }, 200);
      setTimeout(() => { this._desktopElement.classList.add('show'); }, 400);
    }
  }

  animate(type: TProjectCategory): void {
    if (type === this.activeCategory) {
      type = '';
      this.activeCategory = '';
    }

    this.resetAnimations();

    switch (type) {
      case 'Web':
        this.webAnimation.play();
        this._webElement.classList.add('active');
        this.activeCategory = 'Web';
        this.selectedPlatform = 'Web';
        break;
      case 'Mobile':
        this.mobileAnimation.play();
        this._mobileElement.classList.add('active');
        this.activeCategory = 'Mobile';
        this.selectedPlatform = 'Mobile';
        break;
      case 'Desktop':
        this.desktopAnimation.play();
        this._desktopElement.classList.add('active');
        this.activeCategory = 'Desktop';
        this.selectedPlatform = 'Desktop';
        break;

      default:
        this.resetAnimations();
        this.activeCategory = '';
        this.selectedPlatform = 'Main Projects';
    }

    this._loading.show(environment.defaultLoadingMessage);
    this._projectListService.retrieveData(this.activeCategory);
  }
  resetAnimations(): void {
    this._subtitle.classList.add('animate');

    this.webAnimation.stop();
    this.mobileAnimation.stop();
    this.desktopAnimation.stop();

    this._webElement.classList.remove('active');
    this._mobileElement.classList.remove('active');
    this._desktopElement.classList.remove('active');

    this._subtitle.classList.remove('animate');
  }

  setElements(): Promise<void> {
    return new Promise(resolve => {
      const container = document.querySelector('.project-platform-container');
      this._webElement = container.querySelector('ng-lottie:nth-child(1)');
      this._mobileElement = container.querySelector('ng-lottie:nth-child(2)');
      this._desktopElement = container.querySelector('ng-lottie:nth-child(3)');
      this._subtitle = document.querySelector('.projects-main-content-container .projects-page-subtitle');

      resolve();
    });
  }
  processAnimationClass(element: HTMLElement): Promise<void> {
    return new Promise(async () => {
      element.classList.remove('hide');
      element.classList.add('show');
    });
  }
}
