import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISwipeResult, THorizontalSwipe } from '../../views-home-content-projects.interface';
import { ISimpleItemIconModel } from '../../../../../../core/models/simple-item-icon.model';
import { IProjectItemGallery } from 'src/app/core/models/project.model';
import { TGalleryContentFit } from './views-home-content-projects-list-item.interface';
import 'hammerjs/hammer.min';
import { GlComponentOutputsVideoPreviewService } from 'gl-ng-outputs-frontend';

@Component({
  selector: 'app-views-home-content-projects-project-item',
  templateUrl: './views-home-content-projects-list-item.component.html',
  styleUrls: ['./views-home-content-projects-list-item.component.scss']
})
export class ViewsHomeContentProjectsListItemComponent implements OnInit, AfterContentChecked {
  @Input() id: number;
  @Input() icon: string;
  @Input() name: string;
  @Input() shortName: string;
  @Input() description: string;
  @Input() otherTechnologies: string;
  @Input() isMainProject: boolean;
  @Input() category: ISimpleItemIconModel;
  @Input() platform: ISimpleItemIconModel;
  @Input() backend: ISimpleItemIconModel;
  @Input() frontend: ISimpleItemIconModel;
  @Input() database: ISimpleItemIconModel;
  @Input() languages: ISimpleItemIconModel[];
  @Input() gallery: IProjectItemGallery[];

  @Output() nextPreviousProject$: EventEmitter<ISwipeResult> = new EventEmitter();

  player: HTMLVideoElement;

  maxItems: number;
  currentItem: number;
  contentOverviewData: IProjectItemGallery;
  contentFit: TGalleryContentFit = 'contain';
  hideDescription = false;
  contentFitSrc = `assets/img/icon/project-gallery/${this.contentFit === 'contain' ? 'cover' : 'contain'}-content.svg`;
  descriptionVisibilitySrc = `assets/img/icon/project-gallery/${this.hideDescription ? 'show' : 'hide'}-description.svg`;

  randomLanguage = 0;

  pictureOrientation: 'landscape' | 'portrait' | 'equal';
  previousSrc = null;
  isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  constructor(
    private _player: GlComponentOutputsVideoPreviewService,
    private _dc: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getRandomLanguage();
    this.resetContentView();

    if (this.gallery != null && this.gallery.length > 0) {
      const item = this.gallery[0];

      this.contentOverviewData.type = item.type;
      this.contentOverviewData.src = item.src as string;
      this.contentOverviewData.description = item.description;
      this.currentItem = 0;

      this.previousSrc = item.src as string;
    }
  }

  showDescription(): boolean {
    const item = this.description;
    return item != null && item.length > 0;
  }
  showOtherTechnologies(): boolean {
    const item = this.otherTechnologies;
    return item != null && item.length > 0;
  }
  showTypes(item: ISimpleItemIconModel): boolean {
    return item != null && item.name != null && item.name.length > 0 && item.short_name !== 'na';
  }
  showLanguages() {
    const item = this.languages;
    return item != null && item.length > 0 && (item.length === 1 && item[0].short_name != null && item[0].short_name !== 'na');
  }


  ngAfterContentChecked(): void {
    if (this.gallery != null) {
      const currentItem = this.gallery[this.currentItem];

      if (currentItem == null) {
        this.currentItem = 0;
      }

      if (currentItem != null && currentItem.src != null && this.previousSrc !== this.gallery[this.currentItem].src) {
        this.contentOverviewData.type = null;
        this._dc.detectChanges();
      }

      this.maxItems = this.gallery.length;
      this.contentOverviewData = this.gallery[this.currentItem];
      this.previousSrc = this.gallery[this.currentItem].src;

      if (this.contentOverviewData.id != null && this.contentOverviewData.src != null) {
        this.onGalleryClick(this.contentOverviewData);
      }
    }
  }

  getRandomLanguage(): void {
    setInterval(() => {
      if (this.languages != null) {
        const min = 0;
        const max = this.languages.length - 1;

        this.randomLanguage = Math.floor((Math.random() * (max - min + 1)) + min);
      }
    }, 10000);
  }

  onGalleryClick(item: IProjectItemGallery) {
    this.applyContentView(item);
  }

  refreshData(gallery: IProjectItemGallery[]) {
    this._player.stopAll();

    this.maxItems = gallery.length;
    if (this.maxItems > 0) {
      this.currentItem = 0;
    }
  }
  resetContentView() {
    this.contentOverviewData = {
      id: null,
      type: null,
      name: '',
      src: '',
      description: ''
    };
  }
  applyContentView(item: IProjectItemGallery) {
    this.contentOverviewData = {
      id: item.id,
      type: item.type,
      name: item.name,
      src: item.src,
      description: item.description
    };

    this.currentItem = item.id;
    this._dc.detectChanges();
  }
  onSwipeContentOverview(event, type: THorizontalSwipe) {
    event.preventDefault();

    const id = this.currentItem;
    const max = this.maxItems - 1;

    if (type === 'left') {
      if ((id + 1) > max) {
        this.currentItem = 0;
      } else {
        this.currentItem++;
      }
    } else {
      if ((id - 1) < 0) {
        this.currentItem = max;
      } else {
        this.currentItem--;
      }
    }

    this.applyContentView(this.gallery[this.currentItem]);
  }

  setContentFit() {
    if (this.contentFit === 'contain') {
      this.contentFit = 'crop';
      this.contentFitSrc = 'assets/img/icon/project-gallery/contain-content.svg';
    } else {
      this.contentFit = 'contain';
      this.contentFitSrc = 'assets/img/icon/project-gallery/cover-content.svg';
    }
  }
  setDescriptionVisibility() {
    this.hideDescription = !this.hideDescription;
    const name = this.hideDescription ? 'show' : 'hide';

    this.descriptionVisibilitySrc = `assets/img/icon/project-gallery/${ name }-description.svg`;
  }

  onImagePreviewLoad(event: Event, element: HTMLImageElement): void {
    const img = new Image();
    img.src = element.src;

    const width = img.width;
    const height = img.height;

    if (width > height) {
      this.pictureOrientation = 'landscape';
    } else if (width < height){
      this.pictureOrientation = 'portrait';
    } else {
      this.pictureOrientation = 'equal';
    }
  }

  // onPlayerReady(result: IPlayerData): void {
  //     // result.player.play();
  // }

  onPlaying(playing: boolean): void {
    const player = document.querySelector('.overview-content-video #overview-content') as HTMLVideoElement;

    if (player != null) {

      const width = player.videoWidth;
      const height = player.videoHeight;

      if (!playing || width > height) {
        this.pictureOrientation = 'landscape';
      } else if (width < height){
        this.pictureOrientation = 'portrait';
      } else {
        this.pictureOrientation = 'equal';
      }
    }
  }
}
