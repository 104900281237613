import { AfterContentChecked, Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { GlComponentModalLoadingService } from 'gl-ng-modals-frontend';
import { ViewsHomeContentProjectsService } from '../views-home-content-projects.service';
import { ViewsHomeContentProjectsListService } from './views-home-content-projects-list.service';

import { ISwipeResult } from '../views-home-content-projects.interface';
import { TProjectCategory } from '../../../../../core/models/project.model';

@Component({
  selector: 'app-views-home-content-projects-project-list',
  templateUrl: './views-home-content-projects-list.component.html',
  styleUrls: ['./views-home-content-projects-list.component.scss']
})
export class ViewsHomeContentProjectsListComponent implements OnInit, AfterContentChecked {
  @Input() category: TProjectCategory = '';

  currentItem = 0;
  maxItems = 0;
  loadingMessage = environment.defaultLoadingMessage;
  initialized = false;

  constructor(
    public service: ViewsHomeContentProjectsListService,
    private _loading: GlComponentModalLoadingService,
    private _projectsService: ViewsHomeContentProjectsService
  ) {
    _projectsService.isVisible$.pipe(takeUntil(_projectsService.dataUnsubscribe$)).subscribe(async visible => {
      if (visible && !this.initialized) {
        this.initialized = true;
        await this.processData();
        _projectsService.unsubscribeData();
      }
    });
  }

  async processData(): Promise<void> {
    await this.service.retrieveData('');
    this.maxItems = this.service.projectLength.length;
  }

  async ngOnInit(): Promise<void> {
    // await this.service.retrieveData('');

    // this.maxItems = this.service.projectLength.length;
  }

  onSwipeContentOverview(result: ISwipeResult) {
    result.event.preventDefault();
    const type = result.type;
    const id = this.currentItem;

    const max = this.maxItems - 1;

    if (type === 'left') {
      if ((id + 1) > max) {
        this.currentItem = 0;
      } else {
        this.currentItem++;
      }
    } else {
      if ((id - 1) < 0) {
        this.currentItem = max;
      } else {
        this.currentItem--;
      }
    }

    this._loading.show(this.loadingMessage);
    this.service.retrieveData(this.category, this.currentItem);
  }

  applyContentViewFromMark(index: number) {
    this.currentItem = index;
    this._loading.show(this.loadingMessage);
    this.service.retrieveData(this.category, this.currentItem);
  }

  ngAfterContentChecked(): void {
    this.maxItems = this.service.projectLength.length;
  }

  previousNextCallBack() {}
}
