import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ViewsHomeContentProfilesService } from '../views-home-content-profiles.service';
import { ViewsHomeContentProfilesGithubService } from './views-home-content-profiles-github.service';

export interface ISizeChart {
  months: number;
  space: number;
  maxWidth: number;
}

@Component({
  selector: 'app-views-home-content-profiles-github',
  templateUrl: './views-home-content-profiles-github.component.html',
  styleUrls: ['./views-home-content-profiles-github.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ViewsHomeContentProfilesGithubComponent {
  tooltipVisible = false;
  initialized = false;

  constructor(public service: ViewsHomeContentProfilesGithubService, private _profileService: ViewsHomeContentProfilesService) {
    _profileService.isVisible$.pipe(takeUntil(_profileService.gitUnsubscribe$)).subscribe(async visible => {
      if (visible && !this.initialized) {
        this.initialized = true;
        await this.processData();
    //     _profileService.unsubscribeProfile(_profileService.gitUnsubscribe$);
      }
    });
  }

  async processData(): Promise<void> {
    this.service.initialize();

    window.addEventListener('resize', () => {
      this.processChart();
    });
    window.addEventListener('orientationchange', () => {
      this.processChart();
    });

    await this.processChart();
  }

  async processChart() {
    const githubStats = await this.service.GithubStats();
    const githubCommits = document.querySelector('#githubCommits');

    githubCommits.innerHTML = '';

    // Render SVG for commit contributions
    githubCommits.appendChild(githubStats);
  }
}
