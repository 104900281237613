import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INpmPackageData } from './views-home-content-profiles-npm.interface';

@Injectable({
  providedIn: 'root'
})
export class ViewsHomeContentProfilesNpmService {

  constructor(private _http: HttpClient) { }

  public async getData(): Promise<INpmPackageData[]> {
    const url = 'https://admin.glaucioleonardo.com.br/api/profile/npm';

    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Methods', '*');
    headers.append('Access-Control-Allow-Methods', 'GET');

    return new Promise( (resolve, reject) => {
      this._http.get<INpmPackageData[]>(url, { responseType: 'json', headers })
        .subscribe(async (data) => {
          resolve(data);
        },
        error => {
          let errorMessage;

          if (error.includes('Http failure response')) {
            errorMessage = 'Unable to establish connection with server!';
          } else {
            errorMessage = error;
          }

          reject(errorMessage);
        });
    });
  }
}
