<div class="npm-main-content-container">
  <div class="profile-title-container">
    <a rel="noreferrer" class="link-container" href="https://www.npmjs.com/~glaucioleonardo" target="_blank">
      <img
        class="icon"
        alt="NPM icon"
        src="assets/img/logo/npm.svg">
      <h2 class="title">NPM</h2>
      <img
        class="icon external-link"
        alt="External link icon"
        src="assets/img/icon/links/external-link-dark-gray.svg">
    </a>
  </div>

  <p class="description">
    These are some of my published npm packages<strong *ngIf="packages.length > 0"> [{{ packages.length }}]</strong>. Click on this link to see the <a rel="noreferrer" href="https://www.npmjs.com/~glaucioleonardo" class="link">full package list</a>.
  </p>

  <div class="package-container">
    <div class="package-item-container" *ngFor="let item of packages">
      <a rel="noreferrer" [href]="item.url" target="_blank" class="name-container">
        <span class="name">{{ item.name }}</span>
        <span class="publish">{{ item.publish.replace('published ', '') }}</span>
      </a>
      <div class="description-container">
        <p [id]="'description-' + item.id" class="package-description">{{ item.description }}</p>
      </div>
    </div>
  </div>
</div>
