import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlNgModalsFrontendModule } from 'gl-ng-modals-frontend';
import { CoreModule } from '../core/core.module';
import { ViewsHomeContentOurTeamComponent } from './home/content/our-team/views-home-content-our-team.component';
import { ViewsHomeComponent } from './home/views-home.component';
import { ViewsHomeContentComponent } from './home/content/views-home-content.component';
import { SharedModule } from '../shared/shared.module';
import { ViewsHomeContentIntroductionComponent } from './home/content/introduction/views-home-content-introduction.component';
import { MatNativeDateModule } from '@angular/material/core';
import { ViewsHomeContentProjectsComponent } from './home/content/projects/views-home-content-projects.component';
import { ViewsHomeContentProjectsListItemComponent } from './home/content/projects/list/item/views-home-content-projects-list-item.component';
import { ViewsHomeContentProjectsListGalleryComponent } from './home/content/projects/list/gallery/views-home-content-projects-list-gallery.component';
import { ViewsHomeContentProjectsListComponent } from './home/content/projects/list/views-home-content-projects-list.component';
import { ViewsHomeContentProfilesComponent } from './home/content/profiles/views-home-content-profiles.component';
import { ViewsHomeContentProfilesGithubComponent } from './home/content/profiles/github/views-home-content-profiles-github.component';
import { ViewsHomeContentProfilesNpmComponent } from './home/content/profiles/npm/views-home-content-profiles-npm.component';
import { ViewsHomeContentProfilesStackoverflowComponent } from './home/content/profiles/stackoverflow/views-home-content-profiles-stackoverflow.component';
import { ViewsHomeContentProfilesLinkedinComponent } from './home/content/profiles/linkedin/views-home-content-profiles-linkedin.component';
import { GlComponentLogoTextOnlyComponent } from 'gl-ng-logos-frontend';
import { GlComponentMenuComponent } from 'gl-ng-menus-frontend';
import { GlComponentHeaderDefaultComponent } from 'gl-ng-headers-frontend';
import { GlComponentFooterSimpleComponent } from 'gl-ng-footer-frontend';
import { GlComponentButtonImageSimpleComponent } from 'gl-ng-buttons-frontend';
import { GlComponentOutputsVideoPreviewComponent } from 'gl-ng-outputs-frontend';
import { InViewportDirective } from 'ng-in-viewport';
import { LottieComponent } from 'ngx-lottie';

@NgModule({
  declarations: [
    ViewsHomeComponent,
    ViewsHomeContentComponent,
    ViewsHomeContentIntroductionComponent,
    ViewsHomeContentOurTeamComponent,
    ViewsHomeContentProjectsComponent,
    ViewsHomeContentProjectsListItemComponent,
    ViewsHomeContentProjectsListGalleryComponent,
    ViewsHomeContentProjectsListComponent,
    ViewsHomeContentProfilesComponent,
    ViewsHomeContentProfilesGithubComponent,
    ViewsHomeContentProfilesNpmComponent,
    ViewsHomeContentProfilesStackoverflowComponent,
    ViewsHomeContentProfilesLinkedinComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatNativeDateModule,
    CoreModule,
    GlNgModalsFrontendModule,
    GlComponentLogoTextOnlyComponent,
    GlComponentMenuComponent,
    GlComponentHeaderDefaultComponent,
    GlComponentFooterSimpleComponent,
    GlComponentButtonImageSimpleComponent,
    GlComponentOutputsVideoPreviewComponent,
    InViewportDirective,
    LottieComponent
  ]
})
export class ViewsModule { }
