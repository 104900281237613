import { animate, style, transition, trigger } from '@angular/animations';
import { OnDestroy, OnInit } from '@angular/core';
import { AfterViewInit, Component } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { takeUntil } from 'rxjs/operators';
import { CoreServicesAnimationService } from '../../../../core/services/animation/core-services-animation.service';
import { IOurTeamPerson } from './views-home-content-our-team.interface';
import { ViewsHomeContentOurTeamService } from './views-home-content-our-team.service';
import { Location } from '@angular/common';
import { CoreServicesBrowserService } from '../../../../core/services/browser/core-services-browser.service';

@Component({
  selector: 'app-views-home-content-our-team',
  templateUrl: './views-home-content-our-team.component.html',
  styleUrls: ['./views-home-content-our-team.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out',
              style({ height: 300, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 300, opacity: 1 }),
            animate('1s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class ViewsHomeContentOurTeamComponent implements OnInit, AfterViewInit, OnDestroy {
  personAnimated1 = false;
  personAnimated2 = false;
  personAnimated3 = false;
  personAnimated = false;
  visible = false;

  teamPersonOne: HTMLElement;
  teamPersonTwo: HTMLElement;
  teamPersonThree: HTMLElement;
  teamPersonOneTitle: HTMLElement;
  teamPersonTwoTitle: HTMLElement;
  teamPersonThreeTitle: HTMLElement;

  teamAnimation: HTMLElement;
  topLeaves: HTMLElement;
  bottomLeaves: HTMLElement;
  container: HTMLElement;

  teamPersonOneOptions: AnimationOptions = {
    path: '../assets/anims/our-team/person-1/data.json',
    loop: false,
    autoplay: false,
    renderer: 'svg',
    name: 'personOne'
  };
  teamPersonTwoOptions: AnimationOptions = {
    path: '../assets/anims/our-team/person-2/data.json',
    loop: false,
    autoplay: false,
    renderer: 'svg',
    name: 'personTwo'
  };
  teamPersonThreeOptions: AnimationOptions = {
    path: '../assets/anims/our-team/person-3/data.json',
    loop: false,
    autoplay: false,
    renderer: 'svg',
    name: 'personThree'
  };

  teamAnimationOptions: AnimationOptions = {
    path: '../assets/anims/our-team/team-animation/data.json',
    loop: true,
    autoplay: true,
    renderer: 'svg',
    name: 'team-animation'
  };
  topLeavesOptions: AnimationOptions = {
    path: '../assets/anims/our-team/top-leaves/data.json',
    loop: true,
    autoplay: true,
    renderer: 'svg',
    name: 'top-leaves'
  };
  bottomLeavesOptions: AnimationOptions = {
    path: '../assets/anims/our-team/bottom-leaves/data.json',
    loop: true,
    autoplay: true,
    renderer: 'svg',
    name: 'bottom-leaves'
  };

  teamPersonOneAnimation: AnimationItem;
  teamPersonTwoAnimation: AnimationItem;
  teamPersonThreeAnimation: AnimationItem;

  teamMainAnimation: AnimationItem;
  bottomLeavesAnimation: AnimationItem;
  topLeavesAnimation: AnimationItem;

  ourTeam: IOurTeamPerson[] = [];

  constructor(
    private _service: ViewsHomeContentOurTeamService,
    private _animationService: CoreServicesAnimationService,
    private _location: Location,
    private _browser: CoreServicesBrowserService
  ) {
    _service.isVisible$.pipe(takeUntil(this._service.unsubscribe$)).subscribe(visible => {
      this.visible = visible;

      if (visible) {
        this.visible = visible;
      }

      this._animationService.processLoopAnimationVisibility(this.teamAnimation, this.teamMainAnimation, visible);
      this._animationService.processLoopAnimationVisibility(this.topLeaves, this.topLeavesAnimation, visible);
      this._animationService.processLoopAnimationVisibility(this.bottomLeaves, this.bottomLeavesAnimation, visible);
    });
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, false); // third parameter

    const personOne: IOurTeamPerson = {
      index: 1,
      animation: this.teamPersonOneOptions,
      name: 'Gláucio Leonardo',
      position: 'Architect & dear boss'
    };
    const personTwo: IOurTeamPerson = {
      index: 2,
      animation: this.teamPersonTwoOptions,
      name: 'Leonardo Sant\'ana',
      position: 'Project Manager'
    };
    const personThree: IOurTeamPerson = {
      index: 3,
      animation: this.teamPersonThreeOptions,
      name: 'Gláucio Sant\'ana',
      position: 'Full-stack developer'
    };

    this.ourTeam.push(personOne);
    this.ourTeam.push(personTwo);
    this.ourTeam.push(personThree);
  }
  ngAfterViewInit(): void {
    this.container = document.querySelector('#our-team.our-team-main-content-container');

    this.teamPersonOne = this.container.querySelector('.team-animation-person-1');
    this.teamPersonOneTitle = this.container.querySelector('.our-team-person-title-container.index-title-1');

    this.teamPersonTwo = this.container.querySelector('.team-animation-person-2');
    this.teamPersonTwoTitle = this.container.querySelector('.our-team-person-title-container.index-title-2');

    this.teamPersonThree = this.container.querySelector('.team-animation-person-3');
    this.teamPersonThreeTitle = this.container.querySelector('.our-team-person-title-container.index-title-3');

    this.teamAnimation = this.container.querySelector('.team-animation');
    this.topLeaves = this.container.querySelector('.top-leaves-animation');
    this.bottomLeaves = this.container.querySelector('.bottom-leaves-animation');
  }
  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scroll, false); // third parameter
  }

  isInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();

    const height = rect.height;
    const bottom = rect.bottom - height;
    return bottom <= (window.innerHeight || document.documentElement.clientHeight);
  }

  onTeamPersonOnAnimation(animation: AnimationItem, index: number): void {
    animation.stop();

    switch (index) {
      case 1: this.teamPersonOneAnimation = animation; break;
      case 2: this.teamPersonTwoAnimation = animation; break;
      case 3: this.teamPersonThreeAnimation = animation; break;
      case 4: this.teamMainAnimation = animation; break;
      case 5: this.topLeavesAnimation = animation; break;
      case 6: this.bottomLeavesAnimation = animation; break;
    }
  }

  scroll = (): void => {
    if (this.isInViewport(this.container)) {
      if (!this.personAnimated) {
        this.processAnimationVisibility(this.teamPersonOne, this.teamPersonOneAnimation, this.teamPersonOneTitle, 1);

        setTimeout(() => {
          this.processAnimationVisibility(this.teamPersonTwo, this.teamPersonTwoAnimation, this.teamPersonTwoTitle, 2);
        }, 1000);
        setTimeout(() => {
          this.processAnimationVisibility(this.teamPersonThree, this.teamPersonThreeAnimation, this.teamPersonThreeTitle, 3);
        }, 2000);
      }
    }
  }

  processAnimationVisibility(element: HTMLElement, animation: AnimationItem, titleContainer: HTMLElement, index: number): void {
    if (element != null) {
      const personVisible = this.isInViewport(element);

      if (personVisible) {
        animation?.play();
        titleContainer.classList.remove('hide-title');
        titleContainer.classList.add('show-title');

        switch (index) {
          case 1: this.personAnimated1 = true; break;
          case 2: this.personAnimated2 = true; break;
          case 3: this.personAnimated3 = true; break;
        }

        this.personAnimated = this.personAnimated1 && this.personAnimated2 && this.personAnimated3;
        if (this.personAnimated) {
          window.removeEventListener('scroll', this.scroll, false);
        }
      }
    }
  }
}
