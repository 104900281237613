<div class="linkedin-main-content-container">
  <a rel="noreferrer" class="link-container" href="https://www.linkedin.com/in/glaucioleonardo/" target="_blank">
    <img
      class="icon"
      alt="NPM icon"
      src="assets/img/logo/linkedin.svg">
  </a>

  <div class="badge-base LI-profile-badge">
    <div class="profile-badge" dir="ltr">
      <div class="profile-badge__content">

        <h1 class="linkedin-name" itemprop="name">
          Gláucio Leonardo Sant'ana
        </h1>

        <h2 class="linkedin-headline">
          Manufacturing Engineering Software Developer
        </h2>
        <h2 class="linkedin-company">
          FCA Fiat Chrysler Automobiles
        </h2>
      </div>
      <a
        rel="noreferrer"
        class="profile-button"
        href="https://br.linkedin.com/in/glaucioleonardo?trk=public-profile-badge-profile-badge-view-profile-cta"
      >Check the full profile</a>
    </div>
  </div>
</div>
