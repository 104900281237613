<div class="projects-detail-gallery-container">
  <div class="projects-detail-gallery-wrapper">
    <div class="gallery-item-container" *ngFor="let item of content">
      <img
        draggable="false"
        class="gallery-item-type"
        [src]="item.type === 'image' ? 'assets/img/icon/project-gallery/image-icon.svg' : 'assets/img/icon/project-gallery/video-icon.svg'"
        [alt]="item.description">

      <img
        *ngIf="item.type === 'image'"
        draggable="false"
        class="gallery-item"
        [class.last-item]="item.id === content.length - 1"
        [src]="item.src"
        [alt]="item.description"
        (click)="$onClick.emit(item)">

      <img
        *ngIf="item.type === 'video'"
        id="overview-content"
        draggable="false"
        class="gallery-item-video"
        src="assets/img/background/project-video-background-portrait.jpg"
        [class.last-item]="item.id === content.length - 1"
        [alt]="item.description"
        (click)="$onClick.emit(item)">
    </div>
  </div>
</div>
