import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Decimal from 'decimal.js';
import { ISizeChart } from './views-home-content-profiles-github.component';
import { IGithubChartConfig, IGithubChartParams, IGithubCommitsByDay } from './views-home-content-profiles-github.interface';
import {DateGet} from 'gl-w-date-frontend';

@Injectable({
  providedIn: 'root'
})
export class ViewsHomeContentProfilesGithubService {
  currentTooltip: HTMLDivElement;
  contributionCount: HTMLSpanElement;
  contributionDate: HTMLSpanElement;

  constructor(private _http: HttpClient) { }

  initialize(): void {
    this.getCurrentTooltip();
  }

  async GithubStats(): Promise<SVGSVGElement> {
    const data = await this.processData();

    const size = this.processSize();
    const configs: IGithubChartConfig = {
      rows: 7,
      space: 2,
      rectWidth: 17.5,
      months: size.months,
      levelColors: [
        {
          minCommits: 0,
          color: '#161b22'
        },
        {
          minCommits: 1,
          color: '#0e4429'
        },
        {
          minCommits: 3,
          color: '#006d32'
        },
        {
          minCommits: 6,
          color: '#26a641'
        },
        {
          minCommits: 8,
          color: '#39d353'
        }
      ]
    };

    return await this.processSVG(configs, data);
  }

  getText(url, options: any = {}): Promise<string> {
    return fetch(url, options)
      .then(response => {
        if (response.status !== 200) {
          return;
        }
        return response.text();
      });
  }
  // endregion

  // region SVG content
  private async processSVG(config: IGithubChartConfig, commitsByDay: IGithubCommitsByDay[]): Promise<SVGSVGElement> {
    commitsByDay = this.processCommitByDay(commitsByDay, config);

    const rows: number = config.rows || 7;
    const params: IGithubChartParams = {
      rows,
      space: config.space || 2,
      rectWidth: config.rectWidth || 12,
      levelColors: config.levelColors || [
        {
          minCommits: 0,
          color: '#161b22'
        },
        {
          minCommits: 1,
          color: '#0e4429'
        },
        {
          minCommits: 3,
          color: '#006d32'
        },
        {
          minCommits: 6,
          color: '#26a641'
        },
        {
          minCommits: 8,
          color: '#39d353'
        }
      ],
      cols: Math.ceil(commitsByDay.length / rows)
    };

    const svgEl = this.setSvgElement(params);
    const groupEl = this.setSvgGroup();
    let lastMonth: string = null;

    for (let i = 0; i < commitsByDay.length; i++) {
      const date = commitsByDay[i].date;
      const currentMonth = DateGet.customDateString(date, 'YYYY-MM-DD', 'MMM');
      const commits = commitsByDay[i].count;
      const col = Math.floor(i / params.rows);
      const row = i % params.rows;

      let color = null;
      let minCommits = -1;
      const mumCommits = commitsByDay[i].count;

      // region Months label
      this.processMonthLabels(lastMonth, currentMonth, params, col, svgEl);
      lastMonth = currentMonth;
      // endregion

      const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      rect.setAttribute('x', `${col * (params.rectWidth + params.space)}`);
      rect.setAttribute('y', `${row * (params.rectWidth + params.space)}`);
      rect.setAttribute('width', `${params.rectWidth}`);
      rect.setAttribute('height', `${params.rectWidth}`);
      rect.setAttribute('data-date', date);
      rect.setAttribute('data-count', commits.toString());
      rect.setAttribute('rx', '2');
      rect.setAttribute('ry', '2');

      params.levelColors.forEach(levelColor => {
        if (mumCommits >= levelColor.minCommits && levelColor.minCommits > minCommits) {
          minCommits = levelColor.minCommits;
          color = levelColor.color;
        }
      });

      rect.setAttribute('fill', color);
      rect.addEventListener('mouseover', () => {
        this.showPopup(date, commits, rect);
      });
      rect.addEventListener('mouseout', () => {
        this.hidePopup();
      });

      rect.style.position = 'relative';
      rect.style.pointerEvents = 'all';

      groupEl.appendChild(rect);
    }

    svgEl.appendChild(groupEl);

    return svgEl;
  }
  private processCommitByDay(data: IGithubCommitsByDay[], config: any = {}): IGithubCommitsByDay[] {
    return data.filter(x => {
      const year = +x.date.split('-')[0];
      const month = +x.date.split('-')[1];

      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      const currentYearFilter = year === currentYear && month >= currentMonth - config.months;

      if (currentMonth - config.months < 0) {
        let remainingMonths = currentMonth - config.months;

        remainingMonths = remainingMonths < 0 ? remainingMonths * -1 : remainingMonths;
        const previousMonth = 13 - remainingMonths;
        const previousYearFilter = year < currentYear && month >= previousMonth;

        return previousYearFilter || currentYearFilter;
      } else {
        return currentYearFilter;
      }
    });
  }
  private processSize(): ISizeChart {
    const innerWidth = window.innerWidth > 1200 ? 1200 : window.innerWidth;

    if (innerWidth < 360) {
      return {
        months: 2,
        space: 0.8,
        maxWidth: innerWidth
      };
    } else if (innerWidth < 460) {
      return {
        months: 3,
        space: 0.8,
        maxWidth: innerWidth
      };
    } else if (innerWidth < 540) {
      return {
        months: 4,
        space: 0.62,
        maxWidth: innerWidth
      };
    } else if (innerWidth <= 639) {
      return {
        months: 5,
        space: 0.50,
        maxWidth: innerWidth
      };
    } else if (innerWidth < 720) {
      return {
        months: 6,
        space: 0.40,
        maxWidth: innerWidth
      };
    } else if (innerWidth < 800) {
      return {
        months: 8,
        space: 0.40,
        maxWidth: innerWidth
      };
    } else if (innerWidth < 880) {
      return {
        months: 9,
        space: 0.00,
        maxWidth: innerWidth
      };
    } else if (innerWidth < 960) {
      return {
        months: 10,
        space: -0.20,
        maxWidth: innerWidth
      };
    } else if (innerWidth < 1100) {
      return {
        months: 11,
        space: -0.20,
        maxWidth: innerWidth
      };
    }

    return {
      months: 12,
      space: -0.35,
      maxWidth: innerWidth
    };
  }

  private setSvgElement(params: IGithubChartParams): SVGSVGElement {
    const svgEl = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

    svgEl.setAttribute('width', `${params.cols * (params.rectWidth + params.space) - params.space}`);
    svgEl.setAttribute('height', `${params.rows * (params.rectWidth + params.space) - params.space}`);
    svgEl.setAttribute('height', new Decimal(svgEl.height.baseVal.value).plus(15).toString());

    svgEl.classList.add('github-chart');

    return svgEl;
  }
  private setSvgGroup(): SVGGElement {
    return  document.createElementNS('http://www.w3.org/2000/svg', 'g');
  }
  private processMonthLabels(lastMonth: string, currentMonth: string, params: IGithubChartParams, col: number, svgEl: SVGSVGElement) {
    if (lastMonth !== currentMonth) {
      const textEl = document.createElementNS('http://www.w3.org/2000/svg', 'text');

      textEl.setAttribute('x', `${col * (params.rectWidth + params.space)}`);
      textEl.setAttribute('y', '10');
      textEl.setAttribute('fill', '#FFFFFF');
      textEl.textContent = currentMonth;

      svgEl.appendChild(textEl);
    }
  }
  // endregion

  // region Chart Tooltip
  private showPopup(date: string, commits: number, rect: SVGRectElement) {
    const currentDate = this.utcDate(date);
    const count = parseInt(commits.toString() || '', 10);

    const formatted = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      timeZone: 'UTC'
    }).format(currentDate);
    const label = count === 0 ? 'No' : new Intl.NumberFormat('en-US').format(count);

    this.contributionCount.textContent = `${label} ${count === 1 ? 'contribution' : 'contributions'}`;
    this.contributionDate.textContent = `on ${formatted}`;
    this.currentTooltip.style.display = 'flex';

    const iconPos = rect.getBoundingClientRect();
    const x = iconPos.left + window.pageXOffset - this.currentTooltip.offsetWidth / 2 + iconPos.width / 2;
    const y = (iconPos.bottom + window.pageYOffset - this.currentTooltip.offsetHeight - iconPos.height * 2) + 15;

    this.currentTooltip.style.left = x + 'px';
    this.currentTooltip.style.top = y + 'px';

    const graphContainer = document.querySelector('.github-main-content-container');
    // tslint:disable-next-line:no-non-null-assertion
    const graphContainerBounds = graphContainer!.getBoundingClientRect();

    if (this.isTooFarLeft(graphContainerBounds, x)) {
      this.currentTooltip.style.left = `${this.southwestTooltip(iconPos)}px`;
      this.currentTooltip.classList.add('left');
      this.currentTooltip.classList.remove('right');
    } else if (this.isTooFarRight(graphContainerBounds, x)) {
      this.currentTooltip.style.left = `${this.southeastTooltip(iconPos)}px`;
      this.currentTooltip.classList.add('right');
      this.currentTooltip.classList.remove('left');
    } else {
      this.currentTooltip.style.left = `${x}px`;
      this.currentTooltip.classList.remove('left');
      this.currentTooltip.classList.remove('right');
    }
  }
  private hidePopup() {
    this.currentTooltip.style.display = 'none';
  }
  // endregion

  // region SVG Chart Position
  private isTooFarLeft(graphContainerBounds: DOMRect, tooltipX: number) {
    return graphContainerBounds.x > tooltipX;
  }
  private isTooFarRight(graphContainerBounds: DOMRect, tooltipX: number) {
    return graphContainerBounds.x + graphContainerBounds.width < tooltipX + this.currentTooltip.offsetWidth;
  }
  private southwestTooltip(bounds: DOMRect) {
    return bounds.left + window.pageXOffset - this.currentTooltip.offsetWidth * 0.1 + bounds.width / 2 - 10;
  }
  private southeastTooltip(bounds: DOMRect) {
    return bounds.left + window.pageXOffset - this.currentTooltip.offsetWidth * 0.9 + bounds.width / 2 + 10;
  }
  // endregion

  // region SVG Chart Data
  private async processData(): Promise<IGithubCommitsByDay[]> {
    const url = 'https://admin.glaucioleonardo.com.br/api/profile/github';

    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Methods', '*');
    headers.append('Access-Control-Allow-Methods', 'GET');

    return new Promise( (resolve, reject) => {
      this._http.get<IGithubCommitsByDay[]>(url, { responseType: 'json', headers })
        .subscribe(async (data) => {
            resolve(data);
          },
          error => {
            let errorMessage;

            if (error.includes('Http failure response')) {
              errorMessage = 'Unable to establish connection with server!';
            } else {
              errorMessage = error;
            }

            reject(errorMessage);
          });
    });
  }
  private utcDate(iso: string): Date {
    const [year, month, date] = iso.split('-').map(x => parseInt(x, 10));
    return new Date(Date.UTC(year, month - 1, date));
  }
  // endregion

  // region SVG Chart Tooltip
  private getCurrentTooltip(): void {
    this.currentTooltip = document.querySelector('.svg-tooltip');
    this.contributionCount = this.currentTooltip.querySelector('.contribution-count');
    this.contributionDate = this.currentTooltip.querySelector('.contribution-date');

    // Remove pointer events to prevent tooltip flickering
    this.currentTooltip.style.pointerEvents = 'none';
  }
  // endregion
}
