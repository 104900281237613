<div class="github-main-content-container">
  <div class="profile-title-container">
    <a rel="noreferrer" class="link-container" href="https://github.com/glaucioleonardo" target="_blank">
      <img
        class="icon"
        alt="Github icon"
        src="assets/img/logo/github.svg">
      <h2 class="title">Github</h2>
      <img
        class="icon external-link"
        alt="External link icon"
        src="assets/img/icon/links/external-link-dark-gray.svg">
    </a>
  </div>

  <p class="description">
    Check out my latest <a rel="noreferrer" href="https://github.com/glaucioleonardo" class="link">commits on Github</a> and also see <a rel="noreferrer" href="https://github.com/glaucioleonardo" class="link">my full profile</a>.
  </p>

  <div id="githubCommits"></div>
  <div class="github-status">
    <span class="label">Less</span>
    <div class="status item-1"></div>
    <div class="status item-2"></div>
    <div class="status item-3"></div>
    <div class="status item-4"></div>
    <div class="status item-5"></div>
    <span class="label">More</span>
  </div>
</div>

