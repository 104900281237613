import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ViewsHomeContentIntroductionService } from './introduction/views-home-content-introduction.service';
import { ViewsHomeContentOurTeamService } from './our-team/views-home-content-our-team.service';
import { ViewsHomeContentProfilesService } from './profiles/views-home-content-profiles.service';
import { ViewsHomeContentProjectsService } from './projects/views-home-content-projects.service';
import { CoreServicesBrowserService } from '../../../core/services/browser/core-services-browser.service';

@Component({
  selector: 'app-app-views-home-content',
  templateUrl: './views-home-content.component.html',
  styleUrls: ['./views-home-content.component.scss']
})
export class ViewsHomeContentComponent implements OnInit, AfterViewInit {
  introductionContainer: HTMLElement;
  ourTeamContainer: HTMLElement;
  projectsContainer: HTMLElement;
  profilesContainer: HTMLElement;

  isIntroductionVisible = false;
  isOurTeamVisible = false;
  isProjectsVisible = false;
  isProfilesVisible = false;
  allVisible = false;

  constructor(
    public browser: CoreServicesBrowserService,
    private _introductionService: ViewsHomeContentIntroductionService,
    private _ourTeamService: ViewsHomeContentOurTeamService,
    private _projectService: ViewsHomeContentProjectsService,
    private _profileService: ViewsHomeContentProfilesService
  ) { }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, false); // third parameter
    this.scroll();
  }

  ngAfterViewInit(): void {
    window.addEventListener('scroll', this.scroll, false); // third parameter
    this.scroll();

    this.introductionContainer = document.querySelector('#our-team.our-team-main-content-container');
    this.ourTeamContainer = document.querySelector('#our-team.our-team-main-content-container');
    this.projectsContainer = document.querySelector('#projects.projects-main-content-container');
    this.profilesContainer = document.querySelector('#profiles.profiles-main-content-container');
  }

  isInViewport(element: HTMLElement): boolean {
    const rect = element?.getBoundingClientRect();

    if (rect == null) {
      return false;
    }

    const height = rect.height;
    const parsedHeight = (height + (height * 0.2));
    const top = rect.top + parsedHeight;
    const bottom = rect.bottom - parsedHeight;

    const topShown = top >= 0;
    const bottomShown = bottom <= (window.innerHeight || document.documentElement.clientHeight);
    return  topShown && bottomShown;
  }

  scroll = (): void => {
    setTimeout(() => {
      const introduction = this.isInViewport(this.introductionContainer);
      const ourTeam = this.isInViewport(this.ourTeamContainer);
      const project = this.isInViewport(this.projectsContainer);
      const profile = this.isInViewport(this.profilesContainer);

      this._introductionService.isVisible$.next(introduction);
      this._ourTeamService.isVisible$.next(ourTeam);
      this._projectService.isVisible$.next(project);
      this._profileService.isVisible$.next(profile);

      if (introduction) {
        this.isIntroductionVisible = true;
      }
      if (ourTeam) {
        this.isOurTeamVisible = true;
      }
      if (project) {
        this.isProjectsVisible = true;
      }
      if (profile) {
        this.isProfilesVisible = true;
      }

      // if (this.isIntroductionVisible && this.isOurTeamVisible && this.isProjectsVisible && this.isProfilesVisible) {
        // window.removeEventListener('scroll', this.scroll, false);
      // }
    }, 50);
  }

  testar($event: any) {
    console.log($event);
    console.log('oi');
  }
}
