<div class="project-list-content">
  <div class="project-list-content-wrapper">

    <app-views-home-content-projects-project-item
      [class.no-data]="service.projectData == null"

      [id]="service.projectData?.id"
      [icon]="service.projectData?.icon"
      [name]="service.projectData?.name"
      [shortName]="service.projectData?.shortName"
      [description]="service.projectData?.description"
      [otherTechnologies]="service.projectData?.otherTechnologies"
      [isMainProject]="service.projectData?.isMainProject"

      [category]="service.projectData?.category"
      [platform]="service.projectData?.platform"
      [backend]="service.projectData?.backend"
      [frontend]="service.projectData?.frontend"
      [database]="service.projectData?.database"
      [languages]="service.projectData?.languages"

      [gallery]="service.projectData?.gallery"
      (nextPreviousProject$)="onSwipeContentOverview($event)"
    ></app-views-home-content-projects-project-item>

    <div class="project-list-previous-next-active-container">
      <gl-component-button-image-simple
        [class.no-data]="service.projectData == null"
        (click)="onSwipeContentOverview({ event: $event, type: 'right' })"
        [callback]="previousNextCallBack"
        alt="Previous project"
        src="assets/img/icon/drop-down/arrow-down.svg"
        class="arrow previous-project"
      ></gl-component-button-image-simple>

      <div class="project-list-active-container" [class.no-data]="service.projectData == null">
        <div
          *ngFor="let item of service.projectLength == null ? [] : service.projectLength; let i = index"
          class="item {{ currentItem === i ? 'active' : '' }}"
          (click)="applyContentViewFromMark(i)"
        ></div>
      </div>

      <gl-component-button-image-simple
        [class.no-data]="service.projectData == null"
        (click)="onSwipeContentOverview({ event: $event, type: 'left' })"
        [callback]="previousNextCallBack"
        alt="Next project"
        src="assets/img/icon/drop-down/arrow-down.svg"
        class="arrow next-project"
      ></gl-component-button-image-simple>
    </div>

    <p
      [class.no-data]="service.projectData != null"
      class="no-project-available-description"
    >;( There's no project available for this category!</p>
  </div>
</div>
