import { ChangeDetectorRef, Injectable } from '@angular/core';
import { GlComponentModalLoadingService } from 'gl-ng-modals-frontend';
import { IProjectItemGallery, TProjectCategory } from '../../../../../core/models/project.model';
import { ISimpleItemIconModel } from '../../../../../core/models/simple-item-icon.model';
import { CoreServicesDataProjectsService } from '../../../../../core/services/data/projects/core-services-data-projects.service';
import { IProjectDetailData } from './item/views-home-content-projects-list-item.interface';

@Injectable({
  providedIn: 'root'
})
export class ViewsHomeContentProjectsListService {
  projectData: IProjectDetailData;
  projectLength: ISimpleItemIconModel[] = [];
  dc: ChangeDetectorRef;

  constructor(
    public projectService: CoreServicesDataProjectsService,

    private _loading: GlComponentModalLoadingService,
  ) { }

  initialize(dc: ChangeDetectorRef): void {
    this.dc = dc;
  }
  async retrieveData(mainPlatform: TProjectCategory, id: number = 0): Promise<void> {
    const projectLength = await this.projectService.getAll('list', mainPlatform) as any[];

    if (projectLength.length > 0) {
      const projectList: ISimpleItemIconModel[] = [];

      for (const item of projectLength) {
        projectList.push({
          id: projectList.length,
          name: item.name,
          short_name: item.short_name,
          icon: item.icon
        });
      }
      this.projectLength = [...projectList];

      const project = await this.projectService.getById(projectLength[id].id, 'preview', mainPlatform);

      const language: ISimpleItemIconModel[] = [];
      for (const item of project.languages) {
        language.push({
          id: language.length,
          name: item.name,
          short_name: item.short_name,
          icon: item.icon
        });
      }

      const gallery: IProjectItemGallery[] = [];
      for (const item of project.galleries) {
        gallery.push({
          id: gallery.length,
          name: item.name,
          description: item.description,
          type: item.type,
          src: item.src
        });
      }

      this.projectData = {
        id: project.id,
        icon: project.icon,
        name: project.name,
        shortName: project.short_name,
        description: project.description,
        otherTechnologies: project.other_technologies,
        isMainProject: project.is_main_project,

        category: project.types.category,
        platform: project.types.platform,
        backend: project.types.backend,
        frontend: project.types.frontend,
        database: project.types.database,

        languages: [...language],
        gallery: [...gallery],
      };

    } else {
      this.projectData = null;
    }

    this._loading.hide();
  }
}
